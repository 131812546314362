<template>
  <Slider tab-menu :info="list" slides-per-view="auto">
    <template v-slot:slide="{info,idx}">
      <a @click="changePath((/** @type {BaseInfo}*/info).path)" :class="{'active': idx === activeId}">{{ $t((/** @type {BaseInfo}*/info).label) }}</a>
    </template>
  </Slider>
</template>

<script>
import Slider from '@shared/components/general/Slider.vue';
import Specific from '@shared/types/Specific';

export default {
  name: 'TabMenu',
  lexicon: 'promotion.header',
  components: { Slider },
  props: {
    info: /** @type {BaseInfo} */ Specific,
  },
  watch: {
    $route: 'updateRoute',
  },
  data() {
    return {
      activeId: 0,
      list: [
        { label: '_.signup', path: 'Signup', key: 'isEnableSignUpPromotion' },
        { label: '_.deposit', path: 'Deposit', key: 'isEnableDepositPromotion' },
        { label: '_.daily', path: 'Freebie', key: 'isEnableDailyFreebiePromotion' },
        { label: '_.invitation', path: 'Invitation', key: 'isEnableInvitationPromotion' },
        { label: '_.referral', path: 'Referral', key: 'isEnableReferralPromotion' },
      ]
    };
  },
  methods: {
    changePath(path) {
      if (path === this.$route.name) return;
      this.routeName(path, null, this.$route.query);
    },
    updateRoute() {
      this.activeId = this.list.findIndex(o => o.path === this.$route.name);
    },
  },
  async mounted() {
    this.$nextTick(() => {
      this.list = this.list.filter(o => this.info.includes(o.key));
      const routeName = this.$route.name;
      const index = this.list.findIndex(o => routeName === o.path);

      if (index > -1) {
        this.activeId = index;
      } else {
        const path = this.list[0]?.path;
        try {
          if (this.$route.name !== path) this.replaceRouteName(path, null, this.$route.query);
        } catch {
          console.log('route error:::', path, this.$route.name);
        }
      }
    });
  }
};
</script>

<style lang="less">
@import "~@/less/promotion.less";
[tab-menu] { .wf(); .max-w(1030); .mh-c(); .ph(12);
  .swiper-wrapper { .flex(); }
  .swiper-slide { .w();
    > a { .p(20, 30, 12); .block(); .fs(16); .semi-bold(); .c(@c-grey67);
      &.active { .-b(@c-blurRed, 4px); .bold(); .c(@c-blurRed); }
    }
  }

  @media (@tp-up) { .ph(30); }
}
</style>
