<template>
  <div promotion-header>
    <img :src="logo" />
    <TabMenu :info="accessibleList" v-if="promotionInfo" />
  </div>
</template>

<script>
import TabMenu from '@/views/components/pages/promotion/TabMenu.vue';
import { state } from '@shared/utils/storeUtils.mjs';
import { baseList } from '@/constants/base/siteMap';

export default {
  name: 'PromotionHeader',
  components: { TabMenu },
  data() {
    return {
      logoPath: null,
      promotionInfo: null,
    };
  },
  computed: {
    site: state('env', 'site'),
    accessibleList() {
      return Object.keys(this.promotionInfo).filter(o => this.promotionInfo[o]);
    },
    logo() {
      return baseList.find(e => e.site === this.site)?.logo;
    }
  },
  async mounted() {
    try {
      const r = await this.$services.promotion.promotionInfo();
      if (r.error) {
        await this.replaceRouteName(r.path, null, this.$route.query);
      } else {
        this.promotionInfo = r;
      }
    } catch(e) {
      console.log('error : promotion');
    }
  }
};
</script>

<style lang="less" scoped>
@import "~@/less/promotion.less";
[promotion-header] { .sticky(); .t(0); .wf(); .bgc(@c-charcoal); .z(100);
  > img { .h(40); .w(auto); .block(); .mh-c(); .mv(20);
    &:not(:last-child) { .mb(0); }
  }
}
</style>
